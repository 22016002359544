<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import {
  userMethods,
  globalGetters,
  userGetters,
} from "@/state/helpers";

/**
 * Product Detail Component
 */
export default {
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Patient Details",      
    };
  },
  beforeRouteEnter(to, from, next){
    next(vm => {
        vm.$store.dispatch('user/setUserdData',to.params.id)
        }
      );
  },
  created(){
    // this.setUserdD ata(this.$route.params.id);
  },
  computed:{
    ...userGetters,
    ...globalGetters,
    userId(){
      return this.$route.params.id;
    },
  },
  methods: {
    ...userMethods,
    /**
     * Selected image shows
     */
    imageShow(event) {
      const image = event.target.src;
      const expandImg = document.getElementById("expandedImg");
      expandImg.src = image;
    }
  }
};


</script>

<template>
  <Layout>
    <template v-if="loader">
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    
    <div v-else>
    <PageHeader :title="title" />
    <div class="row ">
      <!-- MAIN COLUMN -->
      <div class="col-lg-12 rounded-1 ">
        <!-- PRODUCT DETAILS ONE -->
        <div class="card card-1 " v-if="userData"> 
          <div class="card-body ">
              <div class="product-desc">
                <div class="table-responsive">
                      <table class="table table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row" style="width: 400px;">Name</th>
                            <td>{{ userData.first_name+ ' '+ userData.last_name}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Email</th>
                            <td>{{ userData.user_email}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Gender</th>
                            <td>{{patient.gender}}</td>
                          </tr>
                          <tr v-if="patient.gender == 'female'">
                            <th scope="row">Pregnant, planning a pregnancy or breasfeeding</th>
                            <td>{{patient.pregnant ? patient.pregnant : 'No' }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Date of Birth</th>
                            <td>{{patient.date_of_birth}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Role</th>
                            <td>{{userData.user_roles[0]}}</td>
                          </tr>
                          <tr>
                            <th scope="row">BMI</th>
                            <td>{{patient.bmi}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Post Code</th>
                            <td>{{patient.postcode}}</td>
                          </tr>

                          <tr>
                            <th scope="row">Patient BMI Height Unit</th>
                            <td>{{patient.patient_bmi_height_unit}}</td>
                          </tr>

                          <tr>
                            <th scope="row">Patient BMI Weight Unit</th>
                            <td>{{patient.patient_bmi_weight_unit}}</td>
                          </tr>

                          <tr>
                            <th scope="row">Patient Height</th>
                            <td>{{patient.patient_height.value_1}} , {{patient.patient_height.value_2}}</td>
                          </tr>

                          <tr>
                            <th scope="row">Patient Weight</th>
                            <td>{{patient.patient_weight.value_1}} , {{patient.patient_weight.value_2}}</td>
                          </tr>

                          <tr>
                            <th scope="row">Previously taking medicine</th>
                            <td>{{patient.previously_taking_medicine}}</td>
                          </tr>

                          <tr v-if="patient.weight_related_comorbidity">
                            <th scope="row">Weight related comorbidity</th>
                            <td>{{patient.weight_related_comorbidity}}</td>
                          </tr>

                          <tr v-if="patient.weight_related_comorbidity_explanation">
                            <th scope="row">Weight Related Comorbidity Explanation</th>
                            <td>{{patient.weight_related_comorbidity_explanation}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Registered Date</th>
                            <td>{{userData.user_registered}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>                
              </div>
            

            
          </div>
        </div>
        <!-- PRODUCT DETAILS TWO -->

        <PageHeader title="Shipping Information" v-if="shippingInfo"/>
        <div class="card card-1" v-if="shippingInfo">
          <div class="card-body">
            
              <div class="product-desc">
                <div class="table-responsive">
                      <table class="table table-nowrap mb-0">
                        <tbody>
                          <tr>
                            <th scope="row" style="width: 400px;">First Name</th>
                            <td>{{ shippingInfo.first_name}}</td>
                          </tr>
                          <tr>
                            <th scope="row" style="width: 400px;">Last Name</th>
                            <td>{{ shippingInfo.last_name}}</td>
                          </tr>
                          <tr>
                            <th scope="row">Country</th>
                            <td>{{ shippingInfo.country }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Postal Code</th>
                            <td>{{ shippingInfo.postal_code }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Address 1</th>
                            <td>{{ shippingInfo.address_1 }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Address 2</th>
                            <td>{{ shippingInfo.address_2 }}</td>
                          </tr>
                          <tr>
                            <th scope="row">City</th>
                            <td>{{ shippingInfo.city }}</td>
                          </tr>
                          <tr>
                            <th scope="row">State / Province / Region</th>
                            <td>{{ shippingInfo.state__province__region }}</td>
                          </tr>
                          <tr>
                            <th scope="row">Phone Number</th>
                            <td>{{ shippingInfo.phone_number }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>                
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </Layout>
</template>

